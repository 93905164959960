import * as React from "react";


const ProjectCard = ({icon, title, desc}) => (
  <div className="flex flex-row">
    <div className="flex justify-center items-center flex-shrink-0
        bg-gradient-to-tr from-liteNavy  to-blackNavy rounded-3xl w-32 h-32 mr-5">
      <img className="w-20" src={icon} alt="project icon"/>
    </div>
    <div>
      <div className="font-bold text-2xl mb-2">{title}</div>
      <div className="border bg-white w-8 mb-4"></div>
      <div className="text-gray-100">{desc}</div>
    </div>
  </div>
);
export default ProjectCard;

