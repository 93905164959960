import * as React from "react";
import Seo from "../components/seo";
import Header from "../components/header";
import FeatureCard from "../components/feature-card";
import ProjectCard from "../components/project-card";
import pattern1 from "../images/pattern-1.png";
import pattern2 from "../images/pattern-2.png";
import illustration1 from "../images/illustration-1.png";
import imageBolt from "../images/image-bolt.png";
import imageStar from "../images/image-star.png";
import imageLove from "../images/image-love.png";
import beautydocLogo from "../images/beautydoc-logo.png";
import bikebikeLogo from "../images/bikebike-logo.png";
import beautydocScreen from "../images/beautydoc-screen.png";
import bikebikeScreen from "../images/bikebike-screen.png";
import mailIcon from "../images/email-icon.png";
import phoneIcon from "../images/phone-icon.png";
import pinIcon from "../images/pin-icon.png";
import facebookIcon from "../images/facebook-icon.png";
import twitterIcon from "../images/twitter-icon.png";
import instagram from "../images/instagram-icon.png";
import bylinxLogo from "../images/bylinx-logo.png";
import besteasyLogo from "../images/besteasy-logo.png";
import bylinxScreen from "../images/bylinx-screen.png";
import besteasyScreen from "../images/besteasy-screen.png";
import whatsappIcon from "../images/whatsapp-icon.png";


const IndexPage = () => (
  <div>
    <Seo title="PT Unicorn International Indonesia" />
    <div className="w-full">
      <div className="relative h-screen max-h-640 bg-blackNavy">
        <img src={pattern2} className="absolute z-0 top-0 bottom-0 w-full h-full opacity-50" alt="background pattern"/>
        <div className="content">
          <Header/>
          <div className="flex flex-row mt-20">
            <div className="flex-1 lg:flex-3">
              <div className="font-black text-6xl leading-tight mb-12">
                Enhances <br/>
                Your Digital <br/>
                Experiences</div>
              <p className="text-gray-100 w-full max-w-xl lg:max-w-full">
                Coming with us, get on board, and figure out how to transform your business into the digital era. 
                Unicorn International Indonesia keeps developing great digital projects to enhances 
                your digital experiences. We work on digital transformation to build a better, faster, 
                and simpler platform to evolve your business growth.
              </p>
            </div>
            <div className="flex-7 justify-center items-center hidden lg:flex">
              <img src={illustration1} className="w-4/6" />
            </div>
          </div>
        </div>
      </div>

      <div id="feature" className="bg-navy">
        <div className="content">
          <div className="py-24">
            <h2 className="font-bold text-3xl mb-16">Why Choose Us</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 md:gap-10">
              <FeatureCard 
                image={imageBolt} 
                title={<div className="font-bold text-center text-2xl h-24">Best & Fast Services</div>}
                desc="Here is the solution for all your problems, give us a chance to serve you 
                  the best service you have ever experience."
              />
              <FeatureCard
                image={imageStar}
                title={<div className="font-bold text-center text-2xl h-24">Optimum Quality</div>}
                desc="Quality, to us, is as important as you. Our product are carefully designed for your convenience."
              />
              <FeatureCard
                image={imageLove}
                title={<div className="font-bold text-center text-2xl h-24">Passionate & Inspiring</div>}
                desc="We are always investigating and applying new technologies to provide you with 
                  high quality solutions."
              />
            </div>
          </div>
        </div>
      </div>

      <div id="projects" className="relative bg-blackNavy">
        <img src={pattern1} className="absolute w-full" alt="background pattern" />
        <div className="content">
          <div className="py-24">
            <h2 className="font-bold text-3xl mb-16">What are we developing</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div className="mb-10">
                <ProjectCard icon={beautydocLogo} 
                  title="Beautydoc" 
                  desc="one-stop shopping for your beautiful things, find your inner beauty right now."/>
                <div className="bg-white rounded-3xl mt-10">
                  <img src={beautydocScreen} alt="beautydoc screen"/>
                </div>
              </div>
              <div className="mb-10">
                <ProjectCard icon={bikebikeLogo}
                  title="BikeBike"
                  desc="Designed with love to make it easy for you to find everything about bicycles."/>
                <div className="bg-white rounded-3xl mt-10">
                  <img src={bikebikeScreen} alt="bikebike screen" />
                </div>
              </div>
              <div className="mb-10">
                <ProjectCard icon={bylinxLogo}
                  title="Bylinx"
                  desc="We help make your buying and selling process safer and easier with Bylinx.id"/>
                <div className="bg-white rounded-3xl mt-10">
                  <img src={bylinxScreen} alt="bylinx screen" />
                </div>
              </div>
              <div className="mb-10">
                <ProjectCard icon={besteasyLogo}
                  title="Besteasy"
                  desc="Create new experiences with more attractive and dynamic design of Besteasy. Make your transaction to buy and and sell shares easier and safer."/>
                <div className="bg-white rounded-3xl mt-10">
                  <img src={besteasyScreen} alt="besteasy screen" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact" className="bg-navy">
        <div className="content">
          <div className="py-24">
            <h2 className="font-bold text-3xl mb-16">Contact Us</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
              <div className="flex flex-row">
                <img className="w-8 self-start mr-10" src={mailIcon} alt="email icon"/>
                <div>
                  <div className="mb-2">operation@unicornint.com</div>
                </div>
              </div>
              <div className="flex flex-row">
                <img className="w-8 self-start mr-10" src={pinIcon} alt="pin icon" />
                <div>
                  <div className="mb-2">PT. Unicorn International Indonesia</div>
                  <div>Plaza Mutiara Lt 8 JL. Lingkar Mega Kuningan Kav. E 1.2 No. 1 & 2, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta, 12950</div>
                  <a href="https://wa.me/6281289510581" className="flex flex-row items-center mt-2">
                    <img className="w-4 mr-2" src={whatsappIcon} alt="whatsapp icon" />
                    <div>+62 812-8951-0581</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blackNavy">
        <div className="content">
          <div className="flex flex-col sm:flex-row sm:justify-between py-12">
            <div className="mb-5 sm:mb-0">© 2023 by PT. Unicorn International Indonesia</div>
            <div className="grid grid-cols-3 gap-10 w-40">
              <a href="#">
                <img src={facebookIcon} className="w-8" alt="facebook icon"/>
              </a>
              <a href="#">
                <img src={twitterIcon} className="w-8" alt="twitter icon" />
              </a>
              <a href="#">
                <img src={instagram} className="w-8" alt="instagram icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
)

export default IndexPage;
