import React, {useState} from "react";
import unicornLogo from '../images/unicorn-logo.png';
import menuIcon from '../images/menu-icon.png';
import closeIcon from '../images/close-icon.png';


const Header = () => {

  const [showMobileMenu, setShowMobileMenu] = useState(false);        // mobile only


  const toggleDrawerMenu = () => {
    setShowMobileMenu(currentState => {
      const newState = !currentState;
      document.body.setAttribute('style', `overflow: ${newState ? 'hidden' : 'auto'};`);
      return newState;
    });
  };

  const renderHeaderForDesktop = () => (
    <div className="hidden sm:flex flex-row justify-between items-center w-full h-32">
      <div>
        <img src={unicornLogo} className="w-44 self-center" alt="Unicorn Logo" />
      </div>
      <div>
        <a href="#home" className="menu-item px-5">HOME</a>
        <a href="#feature" className="menu-item px-5">WHY US</a>
        <a href="#projects" className="menu-item px-5">PROJECTS</a>
        <a href="#contact" className="menu-item px-5">CONTACT US</a>
      </div>

    </div>
  );

  const renderHeaderForMobile = () => (
    <div className="flex sm:hidden flex-row justify-between items-center w-full h-32">
      <div>
        <img src={unicornLogo} className="w-44 self-center" alt="Unicorn Logo" />
      </div>
      <div className="p-2" onClick={toggleDrawerMenu}>
        <img src={menuIcon} className="w-6" />
      </div>

      {showMobileMenu && renderMenuDrawerForMobile()}

    </div>
  );

  const renderMenuDrawerForMobile = () => (
    <div className="fixed top-0 bottom-0 left-0 w-full bg-liteNavy z-30">
      <div className="flex items-center justify-end h-32 px-10">
        <div className="p-2" onClick={toggleDrawerMenu}>
          <img src={closeIcon} className="w-6" />
        </div>
      </div>
      <div className="grid grid-cols-1">
        <a onClick={toggleDrawerMenu} href="#home" className="menu-item p-5 mx-auto">HOME</a>
        <a onClick={toggleDrawerMenu} href="#feature" className="menu-item p-5 mx-auto">WHY US</a>
        <a onClick={toggleDrawerMenu} href="#projects" className="menu-item p-5 mx-auto">PROJECTS</a>
        <a onClick={toggleDrawerMenu} href="#contact" className="menu-item p-5 mx-auto">CONTACT US</a>
      </div>
      
    </div>
  )
  

  return (
    <div>
      {renderHeaderForDesktop()}
      {renderHeaderForMobile()}
    </div>
  )
};
export default Header;

