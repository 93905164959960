import * as React from "react";


const FeatureCard = ({ image, title, desc }) => (
  <div className="flex flex-col justify-center items-center 
        bg-gradient-to-tr from-liteNavy  to-blackNavy rounded-3xl px-10 py-10">
    <img src={image} className="w-32 mb-5" alt="Features" />
    <div>{title}</div>
    <div className="border bg-white w-20 mb-8"></div>
    <p className="text-gray-100 text-center">{desc}</p>
  </div>
);
export default FeatureCard;
